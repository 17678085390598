import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import moment from 'moment-timezone'
import heartbeat from 'lib/saga/heartbeat'
import api from 'lib/api/client'
import { SET_NAVIGATION, configureNavigation, setNavigationDate } from 'containers/Navigation/actions'
import { selectNavigationRange } from 'containers/Navigation/selectors'
import { TYPE_RANGE } from 'containers/Navigation/const'
import {
  finishInit,
  receiveLocations,
  receiveSectors,
  receiveReports,
  requestReports,
  startInit
} from './actions'
import {
  selectTimezone,
  selectToken
} from './selectors'

export function * init () {
  yield put(startInit())

  const timezone = yield select(selectTimezone)
  yield put(configureNavigation(TYPE_RANGE))
  yield put(setNavigationDate(moment.tz({ hour: 0 }, timezone)))

  const token = yield select(selectToken)
  const [sectors, locations] = yield all([
    call(api.get, '/sectors', { token }),
    call(api.get, '/locations', { token })
  ])
  yield all([
    put(receiveSectors(sectors)),
    put(receiveLocations(locations))
  ])
  yield call(reports)

  yield put(finishInit())
}

export function * reports () {
  yield put(requestReports())
  const token = yield select(selectToken)
  const params = yield select(selectNavigationRange)
  const reports = yield call(api.get, '/oee/dashboard', { params, token })

  yield put(receiveReports(reports))
}

export default function * root () {
  if (process.env.NODE_ENV === 'development') {
    yield fork(heartbeat, 'OEE Dashboard')
  }

  yield call(init)
  yield takeLatest(SET_NAVIGATION, reports)
}

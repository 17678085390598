import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectSectorIds, selectLocationIdsBySector } from 'oee/dashboard/selectors'
import DashboardSector from 'oee/dashboard/DashboardSector'
import DashboardLocation from 'oee/dashboard/DashboardLocation'

const DashboardTree = ({ sectorIds, idsBySector }) => {
  return (
    <React.Fragment>
      {sectorIds.map(sectorId => (
        <DashboardSector key={sectorId} id={sectorId}>
          {idsBySector[sectorId].map(locationId => (
            <DashboardLocation key={locationId} id={locationId} />
          ))}
        </DashboardSector>
      ))}
    </React.Fragment>
  )
}

DashboardTree.propTypes = {
  sectorIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  idsBySector: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  sectorIds: selectSectorIds(state),
  idsBySector: selectLocationIdsBySector(state)
})

export default connect(mapStateToProps)(DashboardTree)

import PropTypes from 'prop-types'

const reportValueShape = PropTypes.shape({
  value: PropTypes.number.isRequired,
  threshold: PropTypes.number.isRequired
})

export const reportShape = PropTypes.shape({
  oee: reportValueShape,
  availability: reportValueShape,
  performance: reportValueShape,
  quality: reportValueShape
})

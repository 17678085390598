import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Loader from 'components/Loader'
import DashboardHeader from 'oee/dashboard/DashboardHeader'
import DashboardTree from 'oee/dashboard/DashboardTree'
import { selectIsLoading, selectPlant } from 'oee/dashboard/selectors'
import { reportShape } from 'oee/dashboard/shapes'
import OEEGraph from 'components/OEEGraph'

const Dashboard = ({ isLoading, plant }) => {
  if (isLoading) {
    return <Loader full />
  } else {
    return (
      <React.Fragment>
        <DashboardHeader />

        <div className='report__graphs'>
          <OEEGraph report={plant.report.oee} indicator='oee' showLabel />
          <OEEGraph report={plant.report.availability} indicator='availability' showLabel />
          <OEEGraph report={plant.report.performance} indicator='performance' showLabel />
          <OEEGraph report={plant.report.quality} indicator='quality' showLabel />
        </div>

        <DashboardTree />
      </React.Fragment>
    )
  }
}

Dashboard.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  plant: PropTypes.shape({
    report: reportShape
  }).isRequired
}

const mapStateToProps = (state) => ({
  isLoading: selectIsLoading(state),
  plant: selectPlant(state)
})

export default connect(mapStateToProps)(Dashboard)
